
import { defineComponent, ref } from 'vue'
import ClassicEditor from '@/global-components/ckeditor/classic-editor'
import UploadAdapterPlugin from '@/global-components/ckeditor/upload-adapter-plugin'

export default defineComponent({
  setup () {
    const categories = ref(['1', '2'])
    const tags = ref(['1', '2'])
    const salesReportFilter = ref('')
    const classicEditor = ClassicEditor
    const editorConfig = {
      extraPlugins: [
        UploadAdapterPlugin
      ],
      removePlugins: [
        "Markdown",
        "MediaEmbedToolbar"
      ],
      toolbar: {
        items: [
          'fontSize',
          'fontFamily',
          'fontColor',
          'fontBackgroundColor',
          'bold',
          'italic',
          'underline',
          // 'strikethrough',
          'code',
          // 'subscript',
          // 'superscript',
          'link',
          'undo',
          'redo',
          'imageUpload',
          'highlight'
        ]
      },
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:side",
          "linkImage",
        ],
      },
      mediaEmbed: {

      },
    }
    const editorData = ref('<p>Content of the editor.</p>')

    return {
      categories,
      tags,
      salesReportFilter,
      classicEditor,
      editorConfig,
      editorData
    }
  }
})
